import { Button, Divider, Pagination, Stack, StackProps } from "@mui/material";
import { CardHeaderStyled, CardStyled, DarkGreyTextStyled } from "../styled";
import { ActivityCard, ActivityCardProps } from "../cards/ActivityCard";
import { BaseModal } from "../forms/BaseForm";
import { useMobile } from "../../themes";
import { Dispatch, SetStateAction } from "react";

export interface ActivitiesViewProps {
  activities: ActivityCardProps[];
  shouldBeLink?: boolean;
  setPage?: Dispatch<SetStateAction<number>>;
  page?: number;
  count?: number;
}

export function ActivitiesView({
  activities,
  shouldBeLink,
  setPage,
  page,
  count,
}: ActivitiesViewProps) {
  const hasPagination = setPage && page;
  const onPageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    if (setPage) {
      setPage(page);
    }
  };
  return (
    <CardStyled
      sx={{
        height: "100%",
        width: "100%",
        pb: hasPagination ? 2 : 8,
        mb: 3,
        overflowX: "hidden",
      }}
    >
      <CardHeaderStyled
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <DarkGreyTextStyled fontSize={16} fontWeight={600}>
          ACTIVITY
        </DarkGreyTextStyled>
      </CardHeaderStyled>
      <ActivitiesList
        activities={activities}
        shouldBeLink={shouldBeLink}
        sx={{ height: "100%" }}
        updatePagination={onPageChange}
        page={page}
        count={count}
      />
    </CardStyled>
  );
}

export function ActivitiesList({
  activities,
  topDivider,
  bottomDivider = true,
  activityCardPx,
  useArrow,
  shouldBeLink,
  updatePagination,
  page,
  count,
  ...props
}: ActivitiesViewProps & {
  topDivider?: boolean;
  bottomDivider?: boolean;
  activityCardPx?: number | string;
  useArrow?: boolean;
  updatePagination?: (event: React.ChangeEvent<unknown>, page: number) => void;
  page?: number;
  count?: number;
} & StackProps) {
  const limit = 25;
  return (
    <Stack
      gap={"18px"}
      width={"100%"}
      direction={"column"}
      divider={<Divider sx={{ mx: -40 }} variant="fullWidth" />}
      {...props}
      sx={{
        background: "white",
        py: "18px",
        height: "100%",
        overflow: "auto",
        overflowX: "hidden",
        "::-webkit-scrollbar": { display: "none" },
        scrollbarWidth: "none",
        ...props.sx,
      }}
    >
      {topDivider && <></>}
      {activities.map((activity, i) => (
        <ActivityCard
          key={i}
          shouldBeLink={shouldBeLink}
          {...activity}
          px={activityCardPx}
          useArrow={useArrow}
        />
      ))}
      {updatePagination && page && (
        <Stack
          height={"100%"}
          py={1}
          pb={4}
          justifyContent={"flex-end"}
          direction={"row"}
          bgcolor={"white"}
          divider={<Divider variant="fullWidth" />}
        >
          <Pagination
            count={Math.ceil(Number(count ?? 0) / limit)}
            onChange={updatePagination}
            page={page ?? 1}
            color="primary"
            shape="rounded"
            sx={{
              "& .MuiPaginationItem-root": {
                fontSize: "1em",
                "& .MuiSvgIcon-root": {
                  fontSize: "1em",
                },
              },
            }}
          />
        </Stack>
      )}
      {bottomDivider && <></>}
    </Stack>
  );
}

export function ActivitiesModal({
  activities,
  onClose,
  open,
  title,
  useArrow,
}: {
  onClose: () => void;
  open: boolean;
  title: string;
  useArrow?: boolean;
} & ActivitiesViewProps) {
  const isMobile = useMobile();
  const sx = isMobile ? { py: 0 } : {};
  const closeModal = (e: any) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    onClose();
  };
  return (
    <BaseModal onClose={closeModal} open={open} title={title} px={0}>
      <Stack width={"100%"} maxHeight={"70vh"}>
        <ActivitiesList
          activities={activities}
          topDivider
          activityCardPx={isMobile ? 3 : 6}
          sx={{ ...sx, pb: 1 }}
          useArrow={useArrow}
        />
        <Stack
          width={"100%"}
          px={isMobile ? 3 : 6}
          justifyContent={"flex-end"}
          direction={"row"}
        >
          <Button onClick={closeModal} variant="contained">
            Close
          </Button>
        </Stack>
      </Stack>
    </BaseModal>
  );
}
