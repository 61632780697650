import {
  Button,
  IconButton,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  CloseIcon,
  BannerStyled,
  SupportCard,
  UpcomingSessionsSideBar,
  HtmlWrapper,
} from "../../../components";
import { createPortal } from "react-dom";
import { authSelectors, actions } from "../../../state";
import { useMobile, useTablet } from "../../../themes";
import {
  HomepageContainerStyled,
  HomepageSectionStyled,
} from "../shared/HomePage.styles";
import { Link as RouterLink } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useOrgId } from "../../../components/hooks";
import { TaskStats } from "../../../components/misc";
import { SupportChart } from "../../../components/charts";
import { useHeightOffset } from "../../../components/hooks/useHeightOffset";
import { GetSupportStyledCard } from "../../../components/cards/GetSupportStyledCard";

const { useGetOrgAnnouncementsQuery, useDismissAnnouncementMutation } = actions;

export const HomePage = memo(() => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const isSmallerThanPc = isMobile || isTablet;

  const { palette } = useTheme();
  const [portalContainer, sePortalContainer] = useState<HTMLElement | null>(
    null,
  );
  const { first_name } = useSelector(authSelectors.userInfo);

  const orgId = useOrgId();

  const { data: { announcements } = {}, refetch } = useGetOrgAnnouncementsQuery(
    orgId && orgId !== -1
      ? {
          orgId,
        }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );

  const [dismissAnnouncement, { isSuccess }] = useDismissAnnouncementMutation();

  const dismiss = (id: number) => {
    dismissAnnouncement({ id });
  };

  useEffect(() => {
    if (!portalContainer) {
      const container = document.getElementById("homepage-container");
      if (container) sePortalContainer(container);
    }
  }, []);

  useEffect(() => {
    isSuccess && refetch();
  }, [isSuccess]);

  // TopBar is 64px / calc is used to make the shadow display on the whole page (minus TopBar) regardless of the amount of content
  const heightOffset = useHeightOffset();
  return (
    <Stack direction={isSmallerThanPc ? "column" : "row"}>
      <HomepageContainerStyled
        sx={{
          height: isSmallerThanPc
            ? "100%"
            : `calc(100vh - ${65 + heightOffset}px)`, //if multiple orgs selection bar remove
          bgcolor: palette.grey[100],
          borderColor: palette.grey[600],
          flexGrow: 1,
        }}
        id="homepage-container"
      >
        <HomepageSectionStyled>
          <Typography
            fontWeight={600}
            variant="h1"
            fontSize={isMobile ? 14 : "1rem !important"}
          >{`Welcome, ${first_name ?? ""}.`}</Typography>
          <Stack
            direction={isSmallerThanPc ? "column" : "row"}
            justifyContent={"space-between"}
            marginY={"1.5vw"}
            gap={4}
            width="inherit"
          >
            <Stack
              direction={"column"}
              justifyContent={"flex-start"}
              gap={4}
              width={isSmallerThanPc ? "100%" : "50%"}
            >
              <GetSupportStyledCard orgId={orgId} />
              <TaskStats orgId={orgId} />
              {!isSmallerThanPc ? <SupportCard /> : null}
            </Stack>
            <SupportChart />
          </Stack>

          {announcements
            ? announcements
                .filter((s) => new Date(s.end_date) > new Date())
                .map((s) => {
                  const color =
                    s.type === "informational" ? "secondary" : "error";
                  return (
                    <BannerStyled
                      display={"flex"}
                      justifyContent={"space-between"}
                      color={color}
                      size={"small"}
                      flexDirection={isMobile ? "column" : "row"}
                      width={"100%"}
                      type="accent"
                      mb={1}
                      key={s.id!}
                    >
                      <HtmlWrapper message={s.announcement} />
                      <Stack
                        direction={"row"}
                        width={"fit-content"}
                        py={isMobile ? 1 : 0}
                        alignItems={isMobile ? "space-between" : "center"}
                        justifyContent={
                          isMobile
                            ? !!s.button && !!s.button_link
                              ? "space-between"
                              : "flex-end"
                            : "flex-end"
                        }
                      >
                        {!!s.button && !!s.button_link && (
                          <Link
                            component={RouterLink}
                            to={
                              s.button_link.includes("http")
                                ? s.button_link
                                : `https://${s.button_link}`
                            }
                            target="_blank"
                          >
                            <Button variant="contained" color={color}>
                              {s.button}
                            </Button>
                          </Link>
                        )}
                        <IconButton onClick={() => dismiss(s.id!)}>
                          <CloseIcon color={color} />
                        </IconButton>
                      </Stack>
                    </BannerStyled>
                  );
                })
            : null}
        </HomepageSectionStyled>
      </HomepageContainerStyled>
      {!isSmallerThanPc && <UpcomingSessionsSideBar />}
      {portalContainer &&
        isSmallerThanPc &&
        createPortal(
          <>
            <UpcomingSessionsSideBar />
            <Stack width={"100%"} mb={4} px={isMobile ? 0 : 4}>
              <SupportCard />
            </Stack>
          </>,
          portalContainer,
        )}
    </Stack>
  );
});
