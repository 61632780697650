import { Stack, useTheme, LinearProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { clientSelectors, timerSelectors } from "../../../state";
import { memo, useEffect, useState } from "react";
import { useMobile } from "../../../themes";

import { useTimerListener } from "../../../components/hooks/useTimerListener";
import { useTimerSetter } from "../../../components/hooks/useTimerSetter";
import { Clock } from "../../../components/timer/Clock";

export const TimerBar = memo(() => {
  const { checkTimerInferredOrgId } = useTimerSetter();
  useTimerListener();
  const isMobile = useMobile();
  const { palette } = useTheme();
  const [bgColor, setBgColor] = useState(palette.primary.main);
  const isDrawer = useSelector(clientSelectors.taskPreference) === "drawer";
  const initializing = useSelector(timerSelectors.initializing);
  const timerId = useSelector(timerSelectors.timerId);
  const timerState = useSelector(timerSelectors.timerState);
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        checkTimerInferredOrgId();
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () =>
      document.removeEventListener("visibilitychange", handleVisibilityChange);
  }, [checkTimerInferredOrgId]);

  return (
    <Stack
      direction={"row"}
      alignItems={isMobile ? "flex-start" : "center"}
      justifyContent={"space-between"}
      position={"sticky"}
      top={0}
      bgcolor={bgColor}
      color={"#FFF"}
      py={isMobile ? 1 : 0}
      px={isMobile ? 1 : 2}
      sx={(theme) => ({
        zIndex:
          Number(
            isDrawer ? theme.zIndex.drawer ?? 0 : theme.zIndex.modal ?? 0,
          ) + Number(timerId && timerState === "active" ? 3 : 1), // on top of the drawer if there is a timer running
        transition: theme.transitions.create(["height", "display"], {
          duration: theme.transitions.duration.enteringScreen,
        }),
        height: initializing ? 0 : isMobile ? "100%" : "48px",
        display: initializing ? "none !important" : "flex !important",
      })}
      gap={2}
    >
      {initializing === true ? (
        <LinearProgress />
      ) : (
        <Clock setBgColor={setBgColor} />
      )}
    </Stack>
  );
});
