import { useParams } from "react-router-dom";

import {
  Box,
  Button,
  IconButton,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  AddTaskButton,
  ClockIcon,
  CloseIcon,
  CreateSessionForm,
  HistoryActionIcon,
  InlineTextField,
  Modal,
  SessionMenu,
  StatusChip,
} from "../../components";
import { SessionAvatarGroup } from "../../components/icons/SessionAvatarGroup";

import { formatDate, formatDateToHuman } from "../../lib";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useGetStatus, useOrgId } from "../../components/hooks";
import { SessionTimeRange } from "../../components/misc";
import { useMobile } from "../../themes";
import { useSelector } from "react-redux";
import { useCreateUserTimerActivityMutation } from "../../state/rtk-query/state/timer";
import { useActivityActionsList } from "../../components/hooks/useLists";
import {
  GetSessionsByIdApiResponse,
  useUpdateSessionTitleMutation,
} from "../../state/rtk-query/state/session";
import { authSelectors } from "../../state";
type SessionType = { session?: GetSessionsByIdApiResponse };

export const SessionPageHeader = ({ session }: SessionType) => {
  const { palette } = useTheme();
  const { id } = useParams();
  const [openModal, setOpenModel] = useState(false);
  const [openAttendeeModal, setOpenAttendeeModel] = useState(false);
  const [sessionTitle, setSessionTitle] = useState("");
  const { getActionId } = useActivityActionsList();
  const [createTimerActivity] = useCreateUserTimerActivityMutation();
  const [updateSessionTitle] = useUpdateSessionTitleMutation();
  const status = useGetStatus(session?.status_id);
  const isMobile = useMobile();
  const orgId = useOrgId();
  const isInternal = useSelector(authSelectors.isInternal);
  const {
    pending_session_dates: psd,
    title,
    booking,
    status_id,
    org_id,
    deleted_at,
  } = session ?? {};
  const isArchived = Boolean(deleted_at);
  const onClose = useCallback(() => {
    setOpenModel(() => !openModal);
  }, [openModal]);

  const titleStyles = useMemo(
    () => ({
      fontSize: 24,
      fontWeight: 600,
      color: "#383C43",
    }),
    [],
  );

  const sessionOpen = getActionId("session_opened");
  const sessionClosed = getActionId("session_closed");
  useEffect(() => {
    if (sessionOpen) {
      createTimerActivity({
        body: {
          entity: "session",
          entity_id: Number(id),
          action_id: sessionOpen,
        },
      });
    }
    return () => {
      if (sessionClosed) {
        createTimerActivity({
          body: {
            entity: "session",
            entity_id: Number(id),
            action_id: sessionClosed,
          },
        });
      }
    };
  }, [id, sessionOpen, sessionClosed]);
  useEffect(() => {
    setSessionTitle(title || "Consulting Session");
  }, [title]);
  const updateInlineEdit = useCallback(async () => {
    if (id && org_id && !isArchived) {
      await updateSessionTitle({
        id: Number(id),
        orgId: org_id,
        body: { title: sessionTitle?.replace(/(\r\n|\n|\r)/gm, "") },
      });
    }
  }, [id, sessionTitle, title]);

  return (
    <Stack direction="column" gap={isMobile ? 2 : 0}>
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        gap={isMobile ? 2 : 0}
        width={"100%"}
      >
        <Stack
          direction={isMobile ? "column" : "row"}
          width={"100%"}
          flexWrap={"wrap"}
          sx={titleStyles}
        >
          {psd && !booking?.start_time ? (
            <Box pt={1}>
              {" "}
              {`Pending: ${formatDate(psd[0]?.value ?? "")} -  ${formatDate(
                psd[1]?.value ?? "",
              )}`}{" "}
              &middot;
            </Box>
          ) : booking?.start_time ? (
            <Box pt={1}>
              {" "}
              {formatDateToHuman(new Date(booking?.start_time ?? ""))} &middot;
            </Box>
          ) : null}
          <Box mr={0.5} />
          <Box
            width={"80%"}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {!isArchived ? (
              <InlineTextField
                value={sessionTitle}
                onBlur={updateInlineEdit}
                onChange={(e) =>
                  // remove line breaks
                  setSessionTitle(e.target.value?.replace(/(\r\n|\n|\r)/gm, ""))
                }
                style={{
                  ...titleStyles,
                  width: "100%",
                }}
              />
            ) : (
              <Typography
                sx={{
                  ...titleStyles,
                }}
                letterSpacing="inherit"
              >
                {sessionTitle}
              </Typography>
            )}
          </Box>
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={1}
          justifyContent={isMobile ? "space-between" : "flex-end"}
          width={isMobile ? "100%" : undefined}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={1}
            justifyContent={"flex-start"}
          >
            {booking?.duration ? (
              <Stack
                direction={"row"}
                alignItems={"center"}
                whiteSpace={"nowrap"}
                gap={0.5}
              >
                <ClockIcon color={palette.secondary[300]} size={16} />
                <Typography
                  color={palette.secondary[600]}
                  fontWeight={600}
                  fontSize={12}
                  mr={2}
                >
                  {booking.duration} min
                </Typography>
              </Stack>
            ) : null}
            <StatusChip
              statusId={status_id}
              sx={{
                marginRight: session?.session_invitees?.length ? 2 : 0,
              }}
            />
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={1}
            justifyContent={"flex-end"}
          >
            {session ? (
              <SessionAvatarGroup
                session={session}
                setOpenAttendeeModal={setOpenAttendeeModel}
              />
            ) : null}
            {session && Number(id) ? (
              <SessionMenu
                status={status?.internal_name ?? ""}
                session={session}
                id={Number(id)}
                openAttModalFromAvatar={openAttendeeModal}
              />
            ) : null}
          </Stack>
        </Stack>
      </Stack>

      <Stack
        direction={isMobile ? "column" : "row"}
        alignItems={isMobile ? "flex-start" : "center"}
        py={isMobile ? 0 : 1}
        width={"100%"}
        justifyContent={"flex-start"}
        gap={isMobile ? 1 : 6}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          width={isMobile ? "100%" : undefined}
        >
          <ClockIcon color={palette.primary.main} width={18} height={18} />
          {booking ? (
            <Typography variant="subtitle2" fontSize={14} ml={1}>
              <SessionTimeRange
                startTime={booking.start_time}
                endTime={booking.end_time}
              />
              {booking?.play_url ? (
                <Link
                  href={`${booking?.play_url}?pwd=${booking?.meeting_password}`}
                  target="_blank"
                  sx={{ pl: 2, pr: 0 }}
                >
                  Recording
                </Link>
              ) : null}
            </Typography>
          ) : (
            <>
              <Button disabled={Boolean(session?.deleted_at)} onClick={onClose}>
                Schedule a session
              </Button>
              <Modal
                open={openModal}
                onClose={onClose}
                sx={{
                  width: "60%",
                }}
              >
                <Stack
                  direction={"row"}
                  width={"100%"}
                  justifyContent={"flex-end"}
                >
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <CreateSessionForm
                  removeHeader
                  session_id={Number(id ?? 0)}
                  orgId={isInternal ? org_id : orgId}
                />
              </Modal>
            </>
          )}
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          width={isMobile ? "100%" : undefined}
          justifyContent={isMobile ? "space-between" : "flex-start"}
          gap={isMobile ? 2 : 6}
        >
          {session ? (
            <AddTaskButton
              session={session}
              disabled={Boolean(session?.deleted_at)}
              width={18}
              height={18}
              containerWidth={"fit-content"}
            />
          ) : null}
          {session?.created_at ? (
            <Stack direction={"row"} alignItems={"center"}>
              <HistoryActionIcon
                color={palette.primary.main}
                width={18}
                height={18}
                style={{ marginRight: 4 }}
              />
              <Typography
                fontSize={14}
                fontWeight={500}
                color={palette.grey[900]}
              >{`Created ${
                new Date(session?.created_at).getMonth() + 1
              }.${new Date(session?.created_at).getDate()}${
                !isMobile
                  ? ` by ${session?.created_by_user.first_name} ${session?.created_by_user.last_name}`
                  : ""
              }`}</Typography>
            </Stack>
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  );
};
