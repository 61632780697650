/** @file this hook is in charge of setting inferredOrgId and open/close tabs */
import { useCallback, useEffect, useMemo } from "react";
import {
  authSelectors,
  timerActions,
  timerSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import {
  useGetMyActiveTimerQuery,
  useUpdateOpenTabsMutation,
} from "../../state/rtk-query/state/timer";
import { matchPath, useLocation } from "react-router-dom";
import { MainPages } from "../../pages";

export const useTimerSetter = () => {
  const dispatch = useDispatch();
  const [updateOpenTabs] = useUpdateOpenTabsMutation();
  const isCw = useSelector(authSelectors.isCustomWorkUser);
  const taskOrgId = useSelector(timerSelectors.taskOrgId);
  const location = useLocation();
  const isInternal = useSelector(authSelectors.isInternal) && !isCw;
  const { data } =
    useGetMyActiveTimerQuery(undefined, { skip: !isInternal }) ?? {};
  const {
    id: timerId,
    orgId: timerOrgId,
    state: timerState,
    cancelledSwitch,
    stayOnOrgId,
  } = useSelector(timerSelectors.currentTimer) ?? {};
  const inferredOrgId = useSelector(timerSelectors.inferredOrgId);
  const orgId = useMemo(() => {
    const pathMatch = matchPath(
      MainPages(true).organization.path,
      location.pathname,
    );
    return taskOrgId || (pathMatch ? Number(pathMatch.params.id) : undefined);
  }, [taskOrgId, location.pathname]);

  const checkTimerInferredOrgId = useCallback(() => {
    if (!isInternal || !orgId) return;
    if (inferredOrgId !== orgId) {
      dispatch(timerActions.setTimerInferredOrgId(orgId));
    }
  }, [isInternal, orgId, inferredOrgId, dispatch]);
  useEffect(() => {
    console.log("Run checkTimerInferredOrgId");
    checkTimerInferredOrgId();
  }, [checkTimerInferredOrgId, location]);
  useEffect(() => {
    if (!isInternal || !orgId) return;
    if (document.visibilityState === "visible") {
      updateOpenTabs({ orgId, tabCountDirection: "increment" });
    } else {
      // in a switching state
      if (![timerOrgId, stayOnOrgId].includes(orgId)) {
        updateOpenTabs({ orgId, tabCountDirection: "decrement" });
      }
    }
  }, [orgId, isInternal, updateOpenTabs, timerOrgId, stayOnOrgId]);

  // useEffect(() => {
  //   if (!orgId || !isInternal || cancelledSwitch) return;
  //   if (!timerOrgId) {
  //     console.warn("Unknown current org");
  //     return;
  //   }
  //   if (timerState !== "active") {
  //     console.warn("Timer can't be switch if it's not active");
  //     return;
  //   }

  //   if (!data || data.id === 0) {
  //     console.warn("BE returned no active timer");
  //     return;
  //   }
  //   console.log("useTimerSetter useEffect #######", {
  //     timerState,
  //     timerOrgId,
  //     stayOnOrgId,
  //     orgId,
  //     timerId,
  //     d: data?.id,
  //     shouldSwitch: orgId !== timerOrgId && timerState === "active",
  //   });
  //   if (orgId !== timerOrgId && timerState === "active") {
  //     console.log("Switching");
  //     dispatch(timerActions.setTimerStateAction("switching"));
  //   }
  //   // if (
  //   //   [timerOrgId, stayOnOrgId].includes(orgId) === false &&
  //   //   data.id === timerId
  //   // ) {
  //   //   dispatch(timerActions.setTimerStateAction("switching"));
  //   // }
  // }, [
  //   orgId,
  //   stayOnOrgId,
  //   timerOrgId,
  //   cancelledSwitch,
  //   timerState,
  //   timerId,
  //   data?.id,
  //   dispatch,
  //   isInternal,
  //   data,
  // ]);
  return { checkTimerInferredOrgId };
};
