import { Button, FormControlLabel, Stack, Typography } from "@mui/material";
import {
  GetTasksApiArg,
  GetTasksApiResponse,
} from "../../state/rtk-query/state/tasks";
import { useMobile } from "../../themes";
import {
  useAdmireUsersList,
  useOrgsList,
  useStatusesList,
} from "../hooks/useLists";
import { useSelector, authSelectors } from "../../state";
import { CustomCheckBox } from "../styled";
import { ChangeEvent, useMemo } from "react";
import { useTaskParams } from "../hooks/useTaskParams";
import { FilterButton, DateFilterButton } from "../buttons/dropdown";
import { ColoredChip } from "../chips/ColoredChip";
import { PriorityChip } from "../chips";
import { useList } from "../hooks/useNewLists";

type TaskRes = GetTasksApiResponse["rows"][number];

const TYPES_OBJ = {
  quick_question: "Quick Question",
  custom_work: "Custom Work",
  on_session: "On Consulting Session",
  unscheduled: "Unscheduled",
} as const;

type TaskType = {
  [K in keyof typeof TYPES_OBJ]: { id: K; name: (typeof TYPES_OBJ)[K] };
}[keyof typeof TYPES_OBJ];

const TASK_TYPES = Object.entries(TYPES_OBJ).map(([id, name]) => ({
  id,
  name,
})) as TaskType[];

function getTypeOption(type?: string) {
  if (type && type in TYPES_OBJ) {
    return { id: type, name: TYPES_OBJ[type as keyof typeof TYPES_OBJ] };
  }
}

const STATIC_OPTIONS = {
  priority: {
    options: ["normal", "high", "critical"],
    itemProps: ({ value }: { value: "normal" | "high" | "critical" }) => ({
      children: (
        <PriorityChip priority={value} type="external" showPriorityName />
      ),
    }),
  },
  internalPriority: {
    options: ["low", "medium", "high"],
    itemProps: ({ value }: { value: "low" | "medium" | "high" }) => ({
      children: (
        <PriorityChip priority={value} type="internal" showPriorityName />
      ),
    }),
  },
  awaiting: { options: ["admire", "client"] },
} as const;

export interface TaskQueryFilters {
  taskAssignees?: number[];
  taskType?: NonNullable<GetTasksApiArg["type"]>;
  taskTopics?: number[];
  taskStatuses?: string[];
  taskOrgs?: number[];
  taskFilterMyOrgs: boolean;
  taskPriority?: NonNullable<TaskRes["priority"]>[];
  taskInternalPriority?: NonNullable<TaskRes["internal_priority"]>[];
  newComments?: boolean;
  taskOpenTasks?: boolean;
  myTasks?: boolean;
  taskAwaiting?: "admire" | "client";
  page?: number;
  dateRange?: string[];
  taskArchive?: boolean;
}

interface TaskFilterProps {
  isTable?: boolean;
  orgId: number;
  inOrgView?: boolean;
}

export function TaskFilters({ isTable, orgId, inOrgView }: TaskFilterProps) {
  const isMobile = useMobile();
  const isInternal = useSelector(authSelectors.isInternal);
  const isConsultant = useSelector(authSelectors.isConsultant);
  const isAccountManager = useSelector(authSelectors.isAccountManager);
  const isCustomWorkUser = useSelector(authSelectors.isCustomWorkUser);
  const isDeveloper = useSelector(authSelectors.isDeveloper);
  const userId = useSelector(authSelectors.userId);
  const shouldDefaultToSelf = isConsultant || isCustomWorkUser;

  const [params, setParams, clear, isClear] = useTaskParams(isTable);

  const {
    taskFilterMyOrgs,
    taskAssignees,
    taskAwaiting,
    taskInternalPriority,
    taskOpenTasks,
    myTasks,
    taskOrgs,
    taskPriority,
    taskStatuses,
    taskTopics,
    taskType,
    dateRange,
  } = params;

  const topics = useList("topics");

  const { statuses: allStatuses } = useStatusesList("all_tasks");
  const { admireUsers: users, getAdmireUser: getUser } = useAdmireUsersList(
    !isInternal && orgId < 1,
  );
  const sortedUsers = useMemo(
    () =>
      shouldDefaultToSelf && getUser(userId)
        ? [getUser(userId)!, ...users.filter((u) => u.id !== userId)]
        : users,
    [shouldDefaultToSelf, userId, users],
  );
  const { orgs, getOrg } = useOrgsList(inOrgView || !isInternal);

  const statusByNames = useMemo(() => {
    const s = allStatuses.reduce((acc, cur) => {
      if (!acc[cur.internal_name]) {
        acc[cur.internal_name] = {
          id: cur.internal_name,
          name: cur.name,
          color: cur.color,
          deleted_at: !!cur.deleted_at,
          ids: [],
        };
      }
      acc[cur.internal_name].ids.push(cur.id);
      acc[cur.internal_name].deleted_at &&= cur.deleted_at;
      return acc;
    }, {} as Record<string, { id: string; name: string; deleted_at?: any; color: string; ids: number[] }>);
    return Object.values(s);
  }, [allStatuses]);

  const checkBoxStyles = useMemo(
    () => ({
      height: "min-content",
      display: "flex",
      alignItems: "center",
      "& > span:last-child": {
        fontSize: isMobile ? 14 : 16,
        display: "flex",
        alignItems: "center",
        fontWeight: 500,
      },
      whiteSpace: "nowrap",
    }),
    [isMobile],
  );

  return (
    <Stack
      direction={isMobile && !isTable ? "column" : "row"}
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      py={isTable ? 1 : undefined}
      height={!isTable ? "auto" : undefined}
    >
      <Stack
        direction={"row"}
        width={"100%"}
        overflow={"auto"}
        sx={{
          "::-webkit-scrollbar": { display: "none" },
          scrollbarWidth: "none",
        }}
      >
        <FilterButton
          items={statusByNames}
          multiple
          getItem={(v) => (v ? { id: v, name: v } : undefined)}
          selected={taskStatuses}
          setSelected={(val) => setParams({ taskStatuses: val }, "page")}
          capitalize
          itemProps={(_, i) => ({
            children: (
              <ColoredChip color={(i?.color as any) || "grey"} fullWidth>
                {i.name || ""}
              </ColoredChip>
            ),
          })}
        >
          Status
        </FilterButton>
        {!isTable && (
          <DateFilterButton
            value={dateRange as [string, string] | undefined}
            setValue={(v) => setParams({ dateRange: v as any[] }, "page")}
          >
            Date Created
          </DateFilterButton>
        )}
        {isInternal && (
          <FilterButton
            items={sortedUsers}
            multiple
            getItem={getUser}
            selected={taskAssignees}
            setSelected={(val) => setParams({ taskAssignees: val }, "page")}
            withSearch
          >
            Assignee
          </FilterButton>
        )}
        {isTable && (
          <FilterButton
            items={STATIC_OPTIONS.internalPriority.options}
            selected={taskInternalPriority}
            multiple
            setSelected={(val) =>
              setParams({ taskInternalPriority: val }, "page")
            }
            capitalize
            itemProps={STATIC_OPTIONS.internalPriority.itemProps}
          >
            Internal Priority
          </FilterButton>
        )}
        {isTable && (
          <FilterButton
            items={STATIC_OPTIONS.priority.options}
            multiple
            selected={taskPriority}
            setSelected={(v) => setParams({ taskPriority: v }, "page")}
            capitalize
            itemProps={STATIC_OPTIONS.priority.itemProps}
          >
            Priority
          </FilterButton>
        )}
        <FilterButton
          items={TASK_TYPES}
          multiple
          selected={taskType}
          setSelected={(v) => setParams({ taskType: v }, "page")}
          getItem={getTypeOption}
        >
          Type
        </FilterButton>
        <FilterButton
          items={STATIC_OPTIONS.awaiting.options}
          selected={taskAwaiting}
          setSelected={(v) => setParams({ taskAwaiting: v }, "page")}
          capitalize
        >
          Awaiting
        </FilterButton>
        <FilterButton
          items={topics.list}
          getItem={topics.get}
          multiple
          selected={taskTopics}
          setSelected={(v) => setParams({ taskTopics: v }, "page")}
        >
          Topic
        </FilterButton>
        {!inOrgView && isTable && (
          <FilterButton
            items={orgs}
            multiple
            withSearch
            selected={taskOrgs}
            setSelected={(v) => setParams({ taskOrgs: v }, "page")}
            getItem={getOrg}
          >
            Orgs
          </FilterButton>
        )}
        {!inOrgView &&
          isTable &&
          (isConsultant || isAccountManager || isDeveloper) && (
            <FormControlLabel
              sx={{
                height: "min-content",
                display: "flex",
                alignItems: "center",
                "& > span:last-child": {
                  fontSize: isMobile ? 14 : 16,
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 500,
                },
                whiteSpace: "nowrap",
              }}
              labelPlacement="end"
              control={
                <CustomCheckBox
                  props={{
                    checked: taskFilterMyOrgs && !taskOrgs?.length,
                  }}
                  onChange={(
                    _e: ChangeEvent<HTMLInputElement>,
                    checked: boolean,
                  ) =>
                    setParams(
                      {
                        taskFilterMyOrgs: checked,
                      },
                      "taskOrgs",
                    )
                  }
                />
              }
              label={isDeveloper ? "New Admire orgs" : "My orgs"}
            />
          )}
        <FormControlLabel
          sx={checkBoxStyles}
          control={
            <CustomCheckBox
              props={{
                checked: taskOpenTasks && !taskStatuses?.length,
              }}
              onChange={(_e: ChangeEvent<HTMLInputElement>, checked: boolean) =>
                setParams(
                  {
                    taskOpenTasks: checked,
                  },
                  "taskStatuses",
                  "page",
                )
              }
            />
          }
          label="Open tasks"
        />
        {isInternal && (
          <FormControlLabel
            sx={checkBoxStyles}
            control={
              <CustomCheckBox
                props={{
                  checked: myTasks && !taskStatuses?.length,
                }}
                onChange={(
                  _e: ChangeEvent<HTMLInputElement>,
                  checked: boolean,
                ) =>
                  setParams(
                    {
                      myTasks: checked,
                    },
                    "taskAssignees",
                    "taskStatuses",
                    "page",
                  )
                }
              />
            }
            label="My tasks"
          />
        )}
      </Stack>
      {!isClear && (
        <Button
          variant="text"
          color="info"
          sx={{ minWidth: "fit-content" }}
          size="small"
          onClick={clear}
        >
          <Typography fontSize={16} fontWeight={"500"}>
            Reset filters
          </Typography>
        </Button>
      )}
    </Stack>
  );
}
