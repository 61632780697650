import { Node } from "@tiptap/core";

export const MentionHighlightPlugin = Node.create({
  name: "mentionSpan",
  group: "inline",
  inline: true,
  selectable: false,
  atom: true,
  parseHTML() {
    return [
      {
        tag: "span.mention",
        getAttrs: (dom) => {
          const element = dom as HTMLElement;

          const text = element.textContent || "";
          const className = element.getAttribute("class") || "";
          return {
            text,
            class: className,
          };
        },
      },
    ];
  },

  // How to render this node into HTML
  renderHTML({ HTMLAttributes }) {
    const { text, class: className = "", ...validAttributes } = HTMLAttributes;

    if (className) {
      return [
        "span",
        {
          ...validAttributes,
          class: className, // Set the class dynamically
        },
        text || "", // Inner text for the span
      ];
    }

    return text || "";
  },

  // Define attributes for the node
  addAttributes() {
    return {
      class: {
        default: null,
      },
      text: {},
    };
  },
});
