import { Typography, Stack, IconButton, useTheme } from "@mui/material";
import { isDate, pluralize } from "../../lib";
import { ChevronDown } from "../icons";
import { CardStyled } from "../styled";
import { Timer } from "../../global";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { intervalToDuration } from "date-fns";

interface Props {
  timer: Timer;
  expand: number[];
  getTimerGroup: (id: number) => Timer[];
  setExpand: Dispatch<SetStateAction<number[]>>;
}
export const TimerCardHeader = ({
  getTimerGroup,
  timer,
  expand,
  setExpand,
}: Props) => {
  const { palette } = useTheme();
  const isExpanded = expand?.includes(Number(timer?.id));
  const findInGroup = useMemo(
    () =>
      timer?.first_timer_of_group
        ? getTimerGroup(timer?.first_timer_of_group)
            .map((t) => t?.id ?? 0)
            .filter(Boolean)
        : [],
    [timer?.first_timer_of_group],
  );
  const allInGroup = useMemo(
    () =>
      timer?.first_timer_of_group
        ? getTimerGroup(timer?.first_timer_of_group)
        : [],
    [timer?.first_timer_of_group],
  );

  const expandTimer = useCallback(
    (_e: React.MouseEvent<HTMLButtonElement>) => {
      if (isExpanded) {
        setExpand(
          () => expand?.filter((cur) => !findInGroup.includes(cur)) ?? [],
        );
      } else {
        setExpand((old) => [...old, ...findInGroup]);
      }
    },
    [timer?.first_timer_of_group, expand],
  );

  const getTimeDif = useCallback(
    (date?: string, date2?: string) => {
      if (
        !date ||
        !isDate(new Date(date)) ||
        !date2 ||
        !isDate(new Date(date2))
      ) {
        return 0;
      }
      return date && date2
        ? intervalToDuration({
            start: new Date(date),
            end: new Date(date2),
          })
        : 0;
    },
    [timer?.first_timer_of_group],
  );

  const timeDifForGroup = useMemo(
    () =>
      allInGroup.reduce((old: any, acc: any) => {
        const getDif = acc?.duration
          ? {
              hours: Math.floor(Number(acc.duration ?? 0) / 60),
              minutes: Number(acc.duration ?? 0) % 60,
              seconds: Number(acc.duration ?? 0) % 3600,
            }
          : (getTimeDif(acc.start_time, acc.end_time) as any);
        if (old.hours || old.minutes || old.seconds) {
          return {
            ...old,
            hours: old.hours + Number(getDif?.hours ?? 0),
            minutes: old.minutes + Number(getDif?.minutes ?? 0),
            seconds: old.seconds + Number(getDif?.seconds ?? 0),
          };
        }
        return {
          ...old,
          ...getDif,
          hours: getDif.hours,
          minutes: getDif.minutes,
          seconds: getDif.seconds,
        };
      }, {}),
    [timer?.first_timer_of_group],
  );

  return (
    <CardStyled
      sx={{
        px: 2,
        py: 1,
        background: palette.grey[300],
        borderBottomLeftRadius: !isExpanded ? "6px" : "0",
        borderBottomRightRadius: !isExpanded ? "6px" : "0",
        borderWidth: !isExpanded ? "2px" : "1px",
        display: "flex",
        alignItems: "center",
        borderColor: palette.grey[400],
        justifyContent: "space-between",
        flexDirection: "row",
      }}
    >
      <Typography variant="h3">{timer?.org?.name}</Typography>
      <Stack direction={"row"} alignItems={"center"}>
        {timeDifForGroup ? (
          <>
            {Number(timeDifForGroup?.hours ?? 0) > 0 ? (
              <Typography variant="h3">
                {pluralize(Number(timeDifForGroup?.hours ?? 0), "hour")}
              </Typography>
            ) : null}
            <Typography variant="h3" pl={1}>
              {pluralize(Number(timeDifForGroup?.minutes ?? 0), "minute")}
            </Typography>
            <Typography variant="h3" pl={1}>
              {pluralize(Number(timeDifForGroup?.seconds ?? 0), "second")}
            </Typography>
          </>
        ) : null}
        <IconButton
          onClick={expandTimer}
          size="small"
          sx={{ rotate: isExpanded ? "-90deg" : "0" }}
        >
          <ChevronDown size={20} />
        </IconButton>
      </Stack>
    </CardStyled>
  );
};
