import { Dispatch, SetStateAction } from "react";
import { Button, IconButton, Typography, useTheme } from "@mui/material";
import { actions } from "../../state/rtk-query";
import { AddIcon, AttachmentIcon } from "../icons";
import { FileExtensions } from "../../lib";
import { useOrgId } from "../hooks";
import { useMobile } from "../../themes";
const { useSaveMediaMutation, useCreateTaskMediaMutation } = actions;
interface Prop {
  taskId: number;
  isInline?: boolean;
  contained?: boolean;
  setAttachments?: Dispatch<SetStateAction<File[]>>;
}
export const UploadButton = ({
  taskId,
  isInline = false,
  setAttachments,
  contained = false,
}: Prop) => {
  const orgId = useOrgId();
  const [saveMedia] = useSaveMediaMutation();
  const [createTaskMedia] = useCreateTaskMediaMutation();
  const handleFileUpload = async (e: any) => {
    if (!e.target.files) {
      return;
    }
    if (e.target.files.length) {
      if (setAttachments) {
        setAttachments((old) => [...old, ...e.target.files]);
      } else {
        [...e.target.files].map(async (file: any) => {
          const { name } = file ?? {};
          const formData = new FormData();
          formData.append("file", file);

          const missingLabel = name.split(".")[0];

          const retFile = await saveMedia({
            body: { file: formData },
            label: missingLabel,
            name: name || missingLabel,
          }).unwrap();

          if (taskId && retFile.id) {
            await createTaskMedia({
              id: taskId,
              orgId,
              body: { media_id: retFile.id, is_internal: false },
            });
          }
          return;
        });
      }
    }
    return;
  };

  const { palette } = useTheme();
  const isMobile = useMobile();
  return (
    <>
      {isInline ? (
        <IconButton
          component="label"
          size="small"
          sx={{
            fontSize: isMobile ? "1rem" : ".8rem",
            color: palette.grey[700],
            backgroundColor: palette.grey[200],
            boxShadow: "none",
            ml: -1,
            borderRadius: "4px",
            "& > img": {
              filter: "invert(0.6)",
            },
          }}
        >
          <AttachmentIcon
            style={{
              rotate: "315deg",
              fontSize: isMobile ? "1rem" : ".8rem",
              justifyContent: "flex-start !important",
              alignSelf: "flex-start",
            }}
          />
          <input
            type="file"
            hidden
            onChange={handleFileUpload}
            multiple
            accept={FileExtensions.join(", ")}
          />
        </IconButton>
      ) : (
        <Button
          component="label"
          sx={{
            width: "fit-content",
            pl: contained ? "auto" : 0,
            color: contained ? "default" : palette.primary.main,
            "&.MuiButton-root:hover": {
              color: contained ? "default" : palette.grey[700],
            },
          }}
          variant={contained ? "contained" : "text"}
        >
          <AddIcon
            fontSize="small"
            style={{ fontSize: isMobile ? "1rem" : ".8rem" }}
          />
          <Typography fontSize={14} fontWeight={600}>
            Add Attachment
          </Typography>
          <input
            type="file"
            hidden
            onChange={handleFileUpload}
            multiple
            accept={FileExtensions.join(", ")}
          />
        </Button>
      )}
    </>
  );
};
