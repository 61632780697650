import { authSelectors, useSelector } from "../../state";
import { TaskQueryFilters } from "../misc/TaskFilters";
import { useQueryWithDefaults } from "./useQuery";

export function useTaskParams(isTable?: boolean) {
  const userId = useSelector(authSelectors.userId);
  const isConsultant = useSelector(authSelectors.isConsultant);
  const isDeveloper = useSelector(authSelectors.isDeveloper);
  const isAM = useSelector(authSelectors.isAccountManager);
  const isCW = useSelector(authSelectors.isCustomWorkUser);
  const shouldDefaultToSelf = isConsultant || isCW;
  const isInternal = useSelector(authSelectors.isInternal);

  const defaults = {
    taskStatuses: undefined,
    taskType: isCW ? (["custom_work"] as ["custom_work"]) : undefined,
    taskTopics: undefined,
    taskOpenTasks: true,
    myTasks: false,
    taskAwaiting: undefined,
    ...(isInternal && {
      taskAssignees: shouldDefaultToSelf ? [userId] : undefined,
    }),
    ...(isTable
      ? {
          taskOrgs: undefined,
          taskFilterMyOrgs: isConsultant || isDeveloper || isAM || undefined,
          taskInternalPriority: undefined,
          taskPriority: undefined,
          newComments: undefined,
          taskArchive: false,
        }
      : { dateRange: undefined, page: undefined }),
  };

  return useQueryWithDefaults<TaskQueryFilters>(defaults, [
    isCW,
    userId,
    isConsultant,
    isInternal,
    isTable,
  ]);
}
