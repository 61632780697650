import { Stack, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { useMemo } from "react";

import { useGetCwStatsQuery } from "../../state/rtk-query/state/terms";
import { castMinToHours, castZero } from "../../lib";
import { CardStyled } from "../styled";
import { CWUsageCard } from "./CWUsageCard";

export const CWSupportCard = ({ orgId }: { orgId?: number }) => {
  const orgIdArg = orgId ? { orgId: orgId } : skipToken;

  const { currentData } = useGetCwStatsQuery(orgIdArg, {
    refetchOnMountOrArgChange: true,
  });

  const {
    billed_minutes,
    unbilled_minutes,
    available_hours,
    used_hours,
    included_cw,
  } = currentData ?? {};
  const hoursBilled = useMemo(
    () => castMinToHours(billed_minutes),
    [billed_minutes],
  );
  const hoursUnBilled = useMemo(
    () => castMinToHours(unbilled_minutes),
    [unbilled_minutes],
  );
  const hoursUsed = useMemo(() => castZero(used_hours), [used_hours]);
  const hoursAvailable = useMemo(
    () => castZero(available_hours),
    [available_hours],
  );
  const includedCW = useMemo(() => castZero(included_cw), [included_cw]);

  return (
    <CardStyled
      sx={{
        p: 2,
        display: "flex",
        height: "100%",
        flexDirection: "column",
        maxHeight: "fit-content",
        gap: 1,
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h3" fontWeight={600}>
          CUSTOM WORK SUPPORT
        </Typography>
      </Stack>

      <CWUsageCard
        hoursUsed={hoursUsed}
        hoursAvailable={hoursAvailable}
        hoursBilled={hoursBilled}
        hoursUnBilled={hoursUnBilled}
        includedCW={includedCW}
      />
    </CardStyled>
  );
};
