import { FormField } from "./FormField";
import { CustomEditor } from "../../inputs";
import { FieldProps } from "formik";
import { useCustomEditor, UseCustomEditorProps } from "../../hooks";
import { useCallback, useEffect } from "react";

export namespace HtmlField {
  export type Props = FormField.FieldProps<typeof Html>;
}

function Html({
  field,
  form,
  placeholder,
  dependencies,
  setAttachments,
  suggestions,
}: UseCustomEditorProps & FieldProps) {
  const { name, value } = field;
  const { setFieldValue, setFieldTouched, getFieldMeta } = form;
  const { touched, error } = getFieldMeta(name);
  const editor = useCustomEditor({
    setAttachments,
    placeholder,
    defaultText: value,
    dependencies,
    suggestions,
  });
  useEffect(() => {
    if (value !== editor?.getHTML()) {
      editor?.commands.setContent(value);
    }
  }, [value]);
  const onBlur = useCallback(async () => {
    await setFieldValue(name, editor?.isEmpty ? "" : editor?.getHTML());
    setFieldTouched(name, true, true);
  }, [editor, setFieldValue, setFieldTouched, name]);
  return (
    <CustomEditor
      editor={editor}
      placeholder={placeholder}
      onBlur={onBlur}
      error={!!touched && error}
      my={0}
      showUpload={setAttachments ? { taskId: -1, setAttachments } : false}
    />
  );
}

export function HtmlField(props: HtmlField.Props) {
  return <FormField component={Html} {...props} />;
}
