import { skipToken } from "@reduxjs/toolkit/query";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import {
  useGetTaskQuery,
  useGetTaskChatsQuery,
  useGetTaskWatchersQuery,
  GetTaskApiResponse,
  GetTaskChatsApiResponse,
  GetTaskWatchersApiResponse,
} from "../../state/rtk-query/state/tasks";
import { useOrgId } from "../hooks/useOrgId";
import { ReactNode, useEffect, useState } from "react";
import { useQueryParam } from "../hooks";
import { ChangeTaskOrgModal } from "./ChargeTaskOrgModal";
export type TaskSectionType = "description" | "attachments";

export interface TaskValidationProps {
  taskData: GetTaskApiResponse | undefined;
  isFetching: boolean;
  id: number;
  orgId: number;
  closeTask: () => void;
  collapse: TaskSectionType[];
  setCollapse: React.Dispatch<React.SetStateAction<TaskSectionType[]>>;
  taskChats: {
    data: GetTaskChatsApiResponse | undefined;
    isFetching: boolean;
    refetch: () => void;
  };
  taskWatchers: GetTaskWatchersApiResponse | undefined;
}
type ReturnNode = (props: TaskValidationProps) => ReactNode;

interface ValidationWrapper {
  taskId: number;
  closeTask: () => void;
  collapse: TaskSectionType[];
  setCollapse: React.Dispatch<React.SetStateAction<TaskSectionType[]>>;
  ReturnNode: ReturnNode;
}
const ValidateTask = ({
  taskId,
  closeTask,
  collapse,
  setCollapse,
  ReturnNode,
}: ValidationWrapper) => {
  const orgId = useOrgId();
  const isInternal = useSelector(authSelectors.isInternal);
  const {
    currentData: taskData,
    error,
    endpointName,
    isFetching: isFetchingTask,
    refetch: refetchTask,
  } = useGetTaskQuery(
    { id: taskId, orgId },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    refetchTask();
  }, [refetchTask]);
  const isAuthError =
    error &&
    "status" in error &&
    typeof error?.status === "number" &&
    ([403, 401].includes(error.status) ||
      (endpointName === "getTask" && error.status === 404));

  const shouldSkip =
    isAuthError ||
    (!isInternal && taskData?.type === "internal_task") ||
    !taskId ||
    !orgId;

  const {
    data: taskChat,
    refetch,
    isFetching: isFetchingTaskChat,
  } = useGetTaskChatsQuery(!shouldSkip ? { id: taskId, orgId } : skipToken, {
    refetchOnMountOrArgChange: true,
  });
  const { data: taskWatchers } = useGetTaskWatchersQuery(
    !shouldSkip ? { id: taskId, orgId } : skipToken,
    { refetchOnMountOrArgChange: true },
  );
  const { org_id, allow_org_change, reported_by_user } = taskData || {};

  if (isAuthError || (!isInternal && taskData?.type === "internal_task")) {
    closeTask();
    return null;
  }
  return (
    <>
      <ReturnNode
        taskData={taskData}
        isFetching={isFetchingTask && !taskData}
        id={taskId}
        orgId={orgId}
        closeTask={closeTask}
        collapse={collapse}
        setCollapse={setCollapse}
        taskChats={{ data: taskChat, refetch, isFetching: isFetchingTaskChat }}
        taskWatchers={taskWatchers}
      />
      {org_id && allow_org_change && reported_by_user?.id && (
        <ChangeTaskOrgModal
          reportedBy={reported_by_user.id}
          orgId={org_id}
          taskId={taskId}
        />
      )}
    </>
  );
};

export const ValidateTaskWrapper = ({
  ReturnNode,
}: {
  ReturnNode: ReturnNode;
}) => {
  const [taskId, setTaskId] = useQueryParam<number | undefined>("task");
  const [collapse, setCollapse] = useState<TaskSectionType[]>([]);
  if (!taskId) {
    return null;
  }
  return (
    <ValidateTask
      taskId={taskId}
      closeTask={setTaskId}
      collapse={collapse}
      setCollapse={setCollapse}
      ReturnNode={ReturnNode}
    />
  );
};
