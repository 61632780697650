import { useNavigate, useParams } from "react-router-dom";
import {
  useGetSessionActivitiesQuery,
  useGetSessionsByIdQuery,
} from "../../../state/rtk-query/state/session";
import { useGetOrgSessionActivitiesQuery } from "../../../state/rtk-query/state/session";
import { Box, Link, Stack, Typography, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
  CardStyled,
  LeftArrowIcon,
  PageTabs,
  SessionPageHeader,
  SocketMessage,
  TaskCard,
  useRefetchSocket,
} from "../../../components";
import { useLocation } from "../../../lib";
import { useCallback, useEffect, useState } from "react";
import { useOrgId } from "../../../components/hooks";
import { ActivitiesView } from "../../../components/views";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Emptiable, Loadable } from "../../../components/misc";
import { useDispatch, useSelector } from "react-redux";
import { authSelectors, clientActions, clientSelectors } from "../../../state";
import { AddTaskForm } from "../../../components/forms/AddTaskForm";

export const SessionPage = () => {
  const id = Number(useParams().id);
  const { palette } = useTheme();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const orgId = useOrgId();
  const isInternal = useSelector(authSelectors.isInternal);
  const { currentData, isError, isFetching, isSuccess, refetch } =
    useGetSessionsByIdQuery(id && orgId ? { id, orgId } : skipToken, {
      refetchOnMountOrArgChange: true,
    });

  const [openAddTaskForm, setOpenAddTaskForm] = useState(false);
  const shouldRefetch = async (d: SocketMessage) => {
    if (d.tag === "session" && d.message === "refresh" && d?.entity_id === id) {
      refetch();
    }
  };
  useRefetchSocket({ tag: "session", cb: shouldRefetch });
  if (isError) {
    nav("/");
  }

  const { tab = "tasks" } = useLocation().query;
  const to = isInternal
    ? currentData?.org_id
      ? `/organizations/${currentData.org_id}?tab=sessions`
      : ""
    : "/sessions";
  const { screen, sessionId } = useSelector(clientSelectors.currentSupportStep);

  // fixes internal users creating a session and modal closing
  useEffect(() => {
    if (
      currentData?.org_id &&
      sessionId === (id || 0) &&
      screen === "AddTask"
    ) {
      setOpenAddTaskForm(true);
    }
  }, [currentData?.org_id]);

  const closeModal = useCallback(() => {
    dispatch(clientActions.setSupportScreenAction("TypeSelectionInternal"));
    dispatch(clientActions.setSupportSessionId(undefined));
    setOpenAddTaskForm(false);
  }, [screen, sessionId]);

  return (
    <>
      {currentData?.org_id && openAddTaskForm && (
        <AddTaskForm
          orgId={currentData.org_id}
          onClose={closeModal}
          open={openAddTaskForm}
          sessionId={id}
          type="task"
        />
      )}
      <Link
        component={RouterLink}
        to={to}
        color={"inherit"}
        width={"min-content"}
      >
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <LeftArrowIcon color={palette.primary.main} />
          <Typography fontWeight={500} noWrap>
            All sessions
          </Typography>
        </Stack>
      </Link>

      <Box mt={4}>
        <SessionPageHeader session={currentData} />
      </Box>

      <PageTabs
        defaultTab={tab}
        tabValues={[
          { id: "tasks", label: "Tasks" },
          { id: "activity", label: "Activity" },
        ]}
      />
      <Loadable isLoading={isFetching && !currentData}>
        {tab === "tasks" && (
          <Emptiable
            type="tasks"
            orgId={isInternal ? currentData?.org_id : orgId}
            sessionId={id}
            isArchived={!!currentData?.deleted_at}
            isEmpty={isSuccess && !currentData?.tasks?.length}
          >
            <Stack direction={"column"} gap={1}>
              {currentData?.tasks?.map((t) => {
                return (
                  <CardStyled
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    key={t.id}
                  >
                    <TaskCard task={t} hideMenuButtons />
                  </CardStyled>
                );
              })}
            </Stack>
          </Emptiable>
        )}
      </Loadable>
      {tab === "activity" && <SessionActivitiesView id={id} orgId={orgId} />}
    </>
  );
};

function SessionActivitiesView({ id, orgId }: { id?: number; orgId?: number }) {
  const skip = !id || !orgId;
  const hook1 = useGetSessionActivitiesQuery(
    { id: id! },
    { refetchOnMountOrArgChange: true, skip },
  );
  const hook2 = useGetOrgSessionActivitiesQuery(
    { id: id!, orgId: orgId! },
    { refetchOnMountOrArgChange: true, skip },
  );
  const { data: { activities } = {} } = orgId === -1 ? hook1 : hook2;
  if (!activities) return null;
  return <ActivitiesView activities={activities} />;
}
