import {
  AddOrEditOrgModal,
  AvatarGroup,
  CardHeaderStyled,
  CardStyled,
  ChipStyled,
  CWSupportCard,
  InternalAddForm,
  Modal,
  PageTabs,
} from "../../../components";
import { useRouteParams, useLocation, filterBoolean } from "../../../lib";
import { useGetOrgActivitiesQuery } from "../../../state/rtk-query/state/organization";
import { Typography, Stack, Button } from "@mui/material";
import { useState, useCallback, useMemo, useEffect } from "react";
import { UsersTable } from "../../../components/tables/UsersTable";
import {
  TasksView,
  SessionsView,
  ActivitiesView,
} from "../../../components/views";
import { OrgDashboardView } from "../../../components/views/OrgDashboardView";
import {
  useActivityActionsList,
  useAdmireUsersList,
} from "../../../components/hooks/useLists";
import { useTablet, useMobile } from "../../../themes";
import { useDispatch, useSelector } from "react-redux";
import { clientActions, clientSelectors } from "../../../state";
import { useCreateUserTimerActivityMutation } from "../../../state/rtk-query/state/timer";
import { useCurOrg } from "../../../components/hooks";
import { MediaPage } from "../external";
import { SupportChart, SupportByUserChart } from "../../../components/charts";
import { QuickLinksButton } from "../../../layouts/main/drawer/NestedMenu";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";

const tabValues = [
  { id: "agenda", label: "Agenda" },
  { id: "sessions", label: "Sessions" },
  { id: "tasks", label: "Tasks" },
  { id: "users", label: "Users" },
  { id: "activity", label: "Activity" },
  { id: "documents", label: "Documents" },
  { id: "support_usage", label: "Support Usage" },
];

const sx = {
  height: "auto",
  width: "100%",
  pl: 0.75,
  pr: 0.75,
  mb: 1,
  justifyContent: "flex-start",
};
function InternalNote({ children: note }: { children?: string }) {
  if (!note) return null;
  return (
    <ChipStyled
      color="info"
      edge="badge"
      shade="grey"
      type="accent"
      sx={sx}
      label={
        <Typography
          whiteSpace={"pre-wrap"}
          mx={-0.5}
          fontWeight="500"
          fontSize="14"
          letterSpacing="0.01px"
          lineHeight="24px"
        >
          <strong>Internal note: </strong>
          {note}
        </Typography>
      }
    />
  );
}

function LicenseDisplay({
  license,
  licenseStatus,
}: {
  licenseStatus?: string;
  license?: string;
}) {
  if (!license || !licenseStatus) return null;
  return (
    <Typography
      fontSize="14"
      lineHeight={"24px"}
      pt={0.5}
      pb={"10px"}
      fontWeight={500}
    >
      {license} · {licenseStatus}
    </Typography>
  );
}

type NullToUndefined<T> = T extends null
  ? undefined
  : T extends Object
  ? { [K in keyof T]: NullToUndefined<T[K]> }
  : T;

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
);

export const OrganizationPage = () => {
  const id = Number(useRouteParams().id);
  const isTablet = useTablet();
  const isMobile = useMobile();
  const { screen } = useSelector(clientSelectors.currentSupportStep);
  const { getActionId } = useActivityActionsList();
  const [createTimerActivity] = useCreateUserTimerActivityMutation();

  const dispatch = useDispatch();
  const isSmallerThanPc = isTablet || isMobile;

  if (!id) return null;
  const location = useLocation();
  const { tab = "agenda" } = location.query;
  const org = useCurOrg();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);

  const editModal = useCallback(() => setOpenEditModal((prev) => !prev), []);
  const addModal = useCallback(() => {
    dispatch(clientActions.setSupportScreenAction("TypeSelectionInternal"));
    setOpenAddModal((prev) => !prev);
  }, [screen]);
  // const close = useCallback(() => setOpen((prev) => !prev), []);
  const { getAdmireUser } = useAdmireUsersList();
  const orgInternalUsers = useMemo(() => {
    const am = getAdmireUser(org?.account_manager_id);
    const c = getAdmireUser(org?.consultant_id);
    const sub = getAdmireUser(org?.sub_consultant_id);
    return filterBoolean([
      c && { ...c, label: "Consultant", isInternal: true },
      sub && { ...sub, label: "Sub Consultant", isInternal: true },
      am && { ...am, label: "Account Manager", isInternal: true },
    ]);
  }, [org?.account_manager_id, org?.consultant_id, org?.sub_consultant_id]);

  const orgOpen = getActionId("org_opened");
  const orgClosed = getActionId("org_closed");
  useEffect(() => {
    if (orgOpen) {
      createTimerActivity({
        body: { entity: "org", entity_id: id, action_id: orgOpen },
      });
    }
    return () => {
      if (orgClosed) {
        createTimerActivity({
          body: { entity: "org", entity_id: id, action_id: orgClosed },
        });
      }
    };
  }, [id, orgOpen, orgClosed]);

  return (
    <>
      <Stack height={"auto"} my={isMobile ? 1 : 0}>
        <Stack
          direction={isMobile ? "column" : "row"}
          justifyContent="space-between"
          alignItems={isMobile ? "flex-start" : "center"}
        >
          <Typography fontSize="20px" fontWeight="bold" lineHeight={"32px"}>
            {org?.name}
          </Typography>
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={2}
          >
            <QuickLinksButton />
            <AvatarGroup users={orgInternalUsers} maxBeforeGrouping={3} />
            <Button
              variant="outlined"
              color="white"
              onClick={editModal}
              sx={{ width: 50, height: 30, fontWeight: 500 }}
              disabled={!org}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              onClick={addModal}
              sx={{ width: 50, height: 30, fontWeight: 500 }}
              disabled={!org}
            >
              Add
            </Button>
          </Stack>
        </Stack>
        <LicenseDisplay
          license={org?.license_status?.license?.name}
          licenseStatus={org?.license_status?.name}
        />
        <InternalNote>{org?.internal_notes || ""}</InternalNote>
        <PageTabs tabValues={tabValues} defaultTab="agenda" />
      </Stack>
      <Stack overflow={"auto"} flexGrow={1} minHeight={0} minWidth={0}>
        {tab === "agenda" && (
          <OrgDashboardView
            isNewAdmire={
              org?.license_status?.license?.name === "The New Admire"
            }
          />
        )}
        {tab === "sessions" && (
          <SessionsView orgId={id} limit={25} mode="individualOrg" />
        )}
        {tab === "users" && (
          <UsersTable mode="client" orgId={id}>
            {({ AddUserButton, AddUserModel, UsersTable }) => (
              <>
                <CardStyled sx={{ height: "100%", mb: 2 }}>
                  <Stack height={"100%"}>
                    <CardHeaderStyled
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      USERS {AddUserButton}
                    </CardHeaderStyled>
                    {UsersTable}
                  </Stack>
                </CardStyled>
                {AddUserModel}
              </>
            )}
          </UsersTable>
        )}
        {tab === "tasks" && <TasksView orgId={id} />}
        {tab === "activity" && <OrgActivitiesView orgId={id} />}
        {tab === "documents" && <MediaPage orgId={id} />}
        {tab === "support_usage" && (
          <Stack
            height={"fit-content"}
            width={"100%"}
            direction={"column"}
            gap={2}
          >
            <SupportChart />
            <CWSupportCard orgId={id} />
            <SupportByUserChart orgId={id} />
          </Stack>
        )}
      </Stack>
      <AddOrEditOrgModal
        open={openEditModal && !!org}
        onClose={editModal}
        editOrg={org as NullToUndefined<typeof org>}
      />
      {org?.id ? (
        <Modal
          open={openAddModal}
          onClose={addModal}
          sx={{
            width:
              (screen === "CreateSession" || screen === "Done") &&
              !isSmallerThanPc
                ? "60%"
                : isSmallerThanPc
                ? isMobile
                  ? "100%"
                  : "90%"
                : "40%",
            height: isMobile ? "100%" : "max-content",
          }}
        >
          <InternalAddForm orgId={org.id} onClose={addModal} />
        </Modal>
      ) : null}
    </>
  );
};

function OrgActivitiesView({ orgId }: { orgId: number }) {
  const [page, setPage] = useState(1);
  const { data: { activities, count } = {} } = useGetOrgActivitiesQuery(
    { orgId, range: [page - 1, 25] },
    { refetchOnMountOrArgChange: true },
  );
  if (!activities) return null;
  return (
    <ActivitiesView
      activities={activities}
      shouldBeLink
      setPage={setPage}
      page={page}
      count={count}
    />
  );
}
