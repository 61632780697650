import { useTimerActions } from "../hooks";
import { useDispatch, useSelector } from "react-redux";
import { authSelectors, timerActions, timerSelectors } from "../../state";
import { TimerButton } from "./TimerButton";
import { PlayCircleIcon, StopIcon } from "../icons";
import { useActivityActionsList } from "../hooks/useLists";
import { useCreateUserTimerActivityMutation } from "../../state/rtk-query/state/timer";
import { GetTaskApiResponse } from "../../state/rtk-query/state/tasks";
import { useMobile } from "../../themes";
import { TimeSpentButton } from "./TimeSpentButton";
import { Stack } from "@mui/material";
import { castZero } from "../../lib";

interface Props {
  orgId: number;
  taskId: number;
  taskCode: string;
  taskTitle: string;
  type: GetTaskApiResponse["type"];
  taskTimeSpent?: number | null;
}
export const StartCwTimerButton = ({
  orgId,
  taskId,
  taskCode,
  taskTitle,
  type,
  taskTimeSpent,
}: Props) => {
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const isInternal = useSelector(authSelectors.isInternal);
  const isCW = useSelector(authSelectors.isCustomWorkUser);
  const { customWork, state } = useSelector(timerSelectors.currentTimer) ?? {};
  const { getActionId } = useActivityActionsList();
  const [createTimerActivity] = useCreateUserTimerActivityMutation();
  const { startTimer, stopTimer } = useTimerActions();
  const timerIsRunning =
    useSelector(timerSelectors.currentTimer).state === "active";

  const openAction = getActionId("task_opened");

  const onClick = async () => {
    if (timerIsRunning && stopTimer) {
      await stopTimer();
      dispatch(timerActions.setTimerCwAction({ reset: true }));
    } else {
      if (startTimer) {
        await startTimer({ org_id: orgId, task_id: taskId });
        dispatch(
          timerActions.setTimerCwAction({ taskId, taskCode, taskTitle }),
        );
        if (openAction) {
          await createTimerActivity({
            body: { entity: "task", entity_id: taskId, action_id: openAction },
          });
        }
      }
    }
  };

  return (
    <Stack
      direction={"row"}
      alignItems={isMobile ? "flex-start" : "center"}
      gap={1}
    >
      {type === "custom_work" && isInternal ? (
        <TimeSpentButton
          id={taskId}
          time_spent={castZero(taskTimeSpent)}
          orgId={castZero(orgId)}
        />
      ) : null}
      {type === "custom_work" &&
        isCW &&
        (!customWork?.taskId ||
          customWork?.taskId === taskId ||
          state === "stopped") && (
          <TimerButton
            color={timerIsRunning ? "error" : "success"}
            onClick={onClick}
            Icon={timerIsRunning ? StopIcon : PlayCircleIcon}
            text={timerIsRunning ? "Stop" : "Start"}
          />
        )}
    </Stack>
  );
};
